










import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Login extends Vue {
  @Prop() private msg!: string;
  loggedIn = false;
  googleUser: any = {};

  login(): void {
    this.$gAuth.signIn().then((user: any) => {
      this.googleUser = user;
      //   this.loggedIn = true
      console.log(user);
      console.log(user.getId());
      console.log(user.getBasicProfile());
      console.log(user.getAuthResponse());
      console.log(this.$gAuth.GoogleAuth.currentUser.get().getAuthResponse());
      this.loggedIn = this.$gAuth.isAuthorized;
    });
    //   console.log('googleUser', googleUser);
  }

  //   get imageUrl() {
  //     return;
  //   }

  logout(): void {
    this.$gAuth.signOut().then((user: any) => {
      console.log("googleUser", user);
      this.loggedIn = this.$gAuth.isAuthorized;
    });
  }
  // console.log(this.searchFor);
}
